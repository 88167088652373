import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomeView.vue'
import { authGuard } from '@auth0/auth0-vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/log-in-linkedin',
    name: 'log-in-linkedin',
    component: () => import('../views/LogInAuth.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
