<template>
  <n-card class="menu">
    <div class="container">
      <img src="./assets/white-down-arrow.jpeg" alt="logo" @click="goHome">
      <div class="logo"></div>
      <n-menu class="inMenu"  mode="horizontal" :options="menuOptionsNoUser" @update:value="handlerLogin"/>
    </div>
  </n-card>
  <router-view/>


</template>

<script setup>
  import { ref, watchEffect, onMounted, onUnmounted, watch } from 'vue' // used for conditional rendering
  import firebase from 'firebase/compat/app'
  import 'firebase/compat/auth'
  import { useRouter } from 'vue-router'
  import { useAuth0 } from '@auth0/auth0-vue';
  import { defineComponent, h } from "vue";
  import { RouterLink } from "vue-router";
  import { NIcon, useMessage } from 'naive-ui'
  import { NMenu, NCard } from 'naive-ui'
  const router = useRouter()
  const { loginWithRedirect, user, logout } = useAuth0();
  console.log("User is null: " + (user.value ==null))
  console.log("USER::: " + JSON.stringify(user.value))

  var navBarKey = 0;

  const menuOptionsNoUser = [
  {
    label: () => h(RouterLink, {
      to: {
        name: "home",
        
      }
    }, { default: () => "Home" }),
    key: "go-back-home",
  },
  {
  label: () => h(RouterLink, {
      to: {
        name: "log-in-linkedin",

      }
    }, { default: () => "Log In"}),
  key: "log-in-linkedin"
  }
  ]

  function handlerLogin (key, item) {
    console.log("handlerLogin "+ key +" " + JSON.stringify(item))
    if (key=="log-out") {
      console.log("logging out");
      logoutUser();
    }
    if (key=="go-back-home") {
      console.log("clicked home");
    }
  }

  const logoutUser = () => {
    logout({ returnTo: window.location.origin });
  }

  const appChangeColor = () => {
    console.log('change-color in App.vue')
  }

onMounted(() => {
    window.addEventListener('change-color', appChangeColor)
})

onUnmounted(() => {
    window.removeEventListener('change-color', appChangeColor)
})

function goHome() {
  router.push('/')
}

</script>

<style>
/* @import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&family=Open+Sans:wght@300&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: absolute;
  z-index: 99;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
}


nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #4276b9;
}

.menu {
  background-color: #000000;
  color: white;
}

.n-menu.n-menu--horizontal .n-menu-item-content .n-menu-item-content-header a {
  color: white;
  font-weight: 1200;
  font-size: 18px;
}

.n-card {
  color: black;
}

.inMenu {
  color: #0db7ed;
}

html {
    background: white;
}

.menu .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu .container img {
  width:5%;
  transition: 0.5s;
  rotate: 180;
}

.menu .container img:hover{
    background:rgb(29, 29, 29);
}


</style>
