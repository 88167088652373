import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import money from 'v-money3'
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import 'bootstrap/dist/css/bootstrap.min.css'
import mitt from 'mitt'

import { createAuth0 } from '@auth0/auth0-vue';


var firebaseConfig = {
    apiKey: "AIzaSyA6WXPWZjUzda44J0rOWi7yKuWen69_VqI",
    authDomain: "starting-real-estate.firebaseapp.com",
    projectId: "starting-real-estate",
    storageBucket: "starting-real-estate.appspot.com",
    messagingSenderId: "1036818359747",
    appId: "1:1036818359747:web:8fc04abe3ffb4dcefb0976",
    measurementId: "G-XVPN8CYH08"
  };

const firebaseApp = firebase.initializeApp(firebaseConfig);
const auth0 = createAuth0({
  domain: 'dev-1vwjqf9j.us.auth0.com',
  client_id: 'tbcpW4cEhItVZ526IDbMMjGQczkSvjbA',
  redirect_uri: 'https://starting-real-estate.today/',
  // redirect_uri : 'http://localhost:8080/'
})

const emitter = mitt()



console.log("window things: " + window.innerWidth)

// const windowWidth = window.innerWidth
// const windowHeight = window.innerHeight
// const windowDevicePixelRatio = window.devicePixelRatio
export default firebaseApp.firestore();


const app = createApp(App)
app.config.globalProperties.window = window;
app.config.globalProperties.emitter = emitter;
app.provide('emitter', emitter)

app.use(router).use(money).use(auth0).mount('#app')
